<template>
  <div
    :class="['card', `card-layout-${layout}`, `card-${size}`]"
    :style="`--card-img-size: ${imageSize}px`"
  >
    <picture class="print:hidden">
      <source
        v-for="imgTypes in useImageFormats(srcset)"
        :key="imgTypes.type"
        :type="imgTypes.type"
        :srcset="imgTypes.srcsetstring"
        sizes="(min-width: 600px) 400px, calc(100vw - 32px)"
      />
      <img
        v-if="image"
        :width="imageDims.width"
        :height="imageDims.height"
        :src="image"
        :alt="imageAltText"
        :class="['card-img', preserve && 'card-img--preserve']"
        loading="lazy"
        role="none"
      />
    </picture>
    <div class="card-body">
      <component :is="`h${headlineLevel}`" class="card-title">
        {{ title }}
      </component>
      <p class="card-text">{{ text }}</p>
      <div v-if="buttons.length > 0" class="buttons">
        <template v-for="button in buttons" :key="button.id">
          <div class="hidden print:block">{{ BASE_URL }}{{ button.to }}</div>
          <Button
            v-if="button.label"
            class="print:hidden"
            :size="'small'"
            :to="button.to"
            :rounded-corners="button.roundedCorners"
            :layout="button.layout"
            :color="button.color"
          >
            {{ button.label }}
          </Button>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const config = useRuntimeConfig()
  const BASE_URL = config.public.BASE_URL

  withDefaults(
    defineProps<{
      headlineLevel?: string | number
      title: string
      text: string
      image?: string
      imageAltText?: string
      imageSize?: number
      imageDims?: { width: number; height: number }
      layout?: string
      srcset?: { type: string; srcsetstring: string }[]
      size?: string
      buttons?: {
        id: string
        label: string
        to: string
        roundedCorners: boolean
        layout: string
        color: string
      }[]
      preserve?: boolean
    }>(),
    {
      headlineLevel: "2",
      image: "",
      imageAltText: "",
      imageDims: () => ({ width: 0, height: 0 }),
      imageSize: 300,
      layout: "grid",
      srcset: () => [],
      size: "medium",
      buttons: () => [],
      preserve: false,
    },
  )
</script>

<style lang="scss" scoped>
  .card {
    border: $border-width-thin 3solid $gray-100;
    border-radius: $border-radius;
    background: white;
    color: $onyx;
    box-shadow: $shadow;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @media print {
      break-inside: avoid;
    }

    &.card-layout-list {
      @include viewport("m", "md", "lg") {
        display: grid;
        grid-template-columns: minmax(auto, 1fr) 1fr;
        grid-template-rows: 100%;
        align-items: stretch;
      }
    }
  }

  .card-img {
    margin: 0;
    display: block;
    width: 100%;
    height: var(--card-img-size);

    object-fit: cover;

    &.card-img--preserve {
      object-fit: contain;
      width: 100%;
      height: auto;
      max-width: var(--card-img-size);
      margin: 0 auto;
      box-sizing: border-box;
      .card-small & {
        padding: $base-spacing * 3;
      }

      .card-medium & {
        padding: $gutter 0 $gutter $gutter;
      }
    }

    @include viewport("m", "md", "lg") {
      .card-layout-list & {
        height: 100%;
        width: 100%;
      }
    }
  }

  .card-body {
    flex: 1;
    display: grid;
    grid-template-rows: auto 1fr auto;

    .card-small & {
      padding: $gap;
    }

    .card-medium & {
      padding: $base-spacing * 7;
      @media print {
        padding: $space-s;
      }
    }

    .card-layout-list & {
      @include viewport("m", "md", "lg") {
        flex-direction: column;
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }
    }
  }

  .card-title {
    margin: 0;

    .card-small & {
      @include type("h6");
    }

    .card-medium & {
      @include type("h4");
    }
  }

  .card-text {
    color: $gray-text;
    margin: 0;
    @include type("sm");
  }

  .buttons {
    margin-top: $space-s;
    display: flex;
    align-items: flex-start;

    flex-direction: column;
    grid-gap: $base-spacing * 3;

    @media print {
      margin-top: 0;
    }
    .card-layout-list & {
      @include viewport("m", "md", "lg") {
        flex-flow: row wrap;
      }
    }
  }
</style>
